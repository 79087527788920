<template>
  <div v-if="isSuccess">
    <ul class="tw-flex tw-flex-1 tw-flex-col tw-place-content-between tw-pt-2">
      <li
        v-for="(step, index) of statuses"
        :key="`status-${index}`"
        class="tw-flex tw-h-full tw-gap-2"
      >
        <div class="tw-flex tw-w-8 tw-flex-col tw-items-center">
          <div
            class="tw-flex tw-h-8 tw-w-8 tw-items-center tw-justify-center tw-rounded-full tw-text-xs"
            :class="{
              'tw-grey-800 tw-bg-gray-200': index > statusIndex,
              'tw-bg-gray-800 tw-text-white': index <= statusIndex,
            }"
          >
            <Component :is="statusesIcons[index]" />
          </div>
          <div
            v-if="index + 1 !== statuses.length"
            class="tw-h-full tw-flex-1 tw-border-x-2"
            :class="{
              'tw-border-gray-200': index > statusIndex,
              'tw-border-gray-800': index <= statusIndex,
            }"
          />
          <div
            v-if="index + 1 !== statuses.length"
            class="tw-h-full tw-flex-1 tw-border-x-2"
            :class="{
              'tw-border-gray-200': index + 0.5 > statusIndex,
              'tw-border-gray-800': index + 0.5 <= statusIndex,
            }"
          />
        </div>
        <div class="tw-flex-1 tw-pb-12">
          <div class="tw-text-lg tw-font-semibold tw-text-gray-800 first-letter:tw-uppercase">
            {{ step.name }}
          </div>
          <Disclosure v-slot="{ open: isOpen }" :default-open="index === statusIndex">
            <DisclosurePanel class="tw-text-gray-600">
              {{ step.description }}
            </DisclosurePanel>
            <DisclosureButton
              class="tw-text-blue-700 tw-no-underline hover:tw-underline"
              @click="() => trackClick(`View ${isOpen ? 'less' : 'more'}`)"
            >
              View {{ isOpen ? 'less' : 'more' }}
            </DisclosureButton>
          </Disclosure>
        </div>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { MatterType } from '@torticity/types'

import BanknotesIcon from '~icons/heroicons-solid/banknotes.svg'
import ChatIcon from '~icons/heroicons-solid/chat-bubble-left-right'
import ClipboardDocumentCheck from '~icons/heroicons-solid/clipboard-document-check'
import ClipboardDocumentList from '~icons/heroicons-solid/clipboard-document-list'
import DocumentCheck from '~icons/heroicons-solid/document-check'
import Eye from '~icons/heroicons-solid/eye'
import FolderOpenIcon from '~icons/heroicons-solid/folder-open'
import MagnifyingGlass from '~icons/heroicons-solid/magnifying-glass'
import MailIcon from '~icons/heroicons-solid/mail'
import Phone from '~icons/heroicons-solid/phone'
import QuestionMarkCircle from '~icons/heroicons-solid/question-mark-circle'
import SquaresIcon from '~icons/heroicons-solid/square-2-stack'

const autoAccidentIconConfig = [
  SquaresIcon,
  FolderOpenIcon,
  MailIcon,
  ChatIcon,
  FolderOpenIcon,
  QuestionMarkCircle,
  BanknotesIcon,
]

const tortIconConfig = [
  ClipboardDocumentList,
  Phone,
  DocumentCheck,
  MagnifyingGlass,
  Eye,
  ClipboardDocumentCheck,
  BanknotesIcon,
]

const { data, isSuccess } = useClaimStatusQuery()

const analytics = useAnalytics()

const { $auth } = useNuxtApp()

function trackClick(text: string) {
  analytics.trackElementClicked({
    elementType: 'button',
    text,
  })
}

const statusesIcons = computed(() => {
  return $auth.user?.lead?.matterType === MatterType.AUTO_ACCIDENT
    ? autoAccidentIconConfig
    : tortIconConfig
})

const statuses = computed(() => data.value?.statuses ?? [])
const statusIndex = computed(() => data.value?.status?.index ?? 0)
</script>
