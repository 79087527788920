<template>
  <AppDrawer v-model="open">
    <template #title>
      <img
        v-if="whiteLabeled"
        :src="logoUrl"
        :alt="brand.name"
        class="tw-max-w-52 tw-h-auto tw-max-h-14 tw-w-auto tw-max-w-sm"
      />
    </template>
    <DashboardTracker />
  </AppDrawer>
</template>

<script setup lang="ts">
import { useVModel } from '@vueuse/core'

const brand = useBrand()
const whiteLabeled = computed(() => brand.value.brandingExperience === 'white_label')

const props = withDefaults(defineProps<{ modelValue: boolean }>(), { modelValue: false })

const emit = defineEmits(['update:modelValue'])

const open = useVModel(props, 'modelValue', emit)

const logoUrl = computed(() => {
  return brand.value.svgLogoUrl || brand.value.pngLogoUrl
})
</script>
