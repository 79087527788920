<template>
  <footer
    class="tw-h-fit-content tw-w-full tw-flex-col tw-justify-self-center tw-pb-20 tw-text-xs tw-text-gray-600 md:tw-pb-0 lg:tw-left-0 lg:tw-right-0"
  >
    <div v-if="isCobranded && !isError" class="tw-flex tw-w-full tw-justify-center tw-py-12">
      <PoweredByTorticity />
    </div>
    <hr />
    <div
      id="footer-div"
      class="tw-flex tw-w-full tw-flex-col tw-flex-wrap tw-justify-between tw-gap-2 tw-bg-white tw-p-4 tw-text-center lg:tw-flex-row lg:tw-items-start"
    >
      <p>Powered by Torticity © {{ new Date().getFullYear() }}</p>
      <div class="tw-mt-4 tw-flex tw-flex-wrap tw-items-center lg:tw-mt-0">
        <div class="tw-mx-auto tw-flex tw-flex-wrap tw-justify-center tw-gap-7">
          <p>
            <a
              href="https://www.torticity.com/terms-of-use"
              target="_blank"
              class="hover:tw-underline"
            >
              Terms of use
            </a>
          </p>
          <p>
            <a
              href="https://www.torticity.com/acceptable-use-policy"
              target="_blank"
              class="hover:tw-underline"
            >
              Acceptable use
            </a>
          </p>
          <p>
            <a
              href="https://www.torticity.com/disclaimer"
              target="_blank"
              class="hover:tw-underline"
            >
              Disclaimer
            </a>
          </p>
          <p>
            <a
              href="https://www.torticity.com/privacy-notice"
              target="_blank"
              class="hover:tw-underline"
            >
              Privacy notice
            </a>
          </p>
          <p>
            <a
              href="https://www.torticity.com/cookie-policy"
              target="_blank"
              class="hover:tw-underline"
            >
              Cookie policy
            </a>
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
import { defineProps } from 'vue'

import PoweredByTorticity from '~/assets/icons/PoweredByTorticity.svg'

defineProps({
  isError: {
    type: Boolean,
    default: false,
  },
})

const branding = useBrand()

const isCobranded = computed(() => branding.value.brandingExperience === 'cobrand')
</script>

<style>
@media (min-width: 1024px) {
  #footer-div {
    justify-content: center;
  }
}
@media (min-width: 1080px) {
  #footer-div {
    justify-content: space-between;
  }
}
</style>
