<template>
  <div>
    <DashboardSkeleton v-show="isLoading" />

    <section v-show="!isLoading" class="tw-min-h-screen">
      <DashboardTrackerDrawer v-model="open" />
      <div class="tw-h-[inherit] tw-min-h-[inherit] md:tw-flex">
        <aside
          class="tw-hidden tw-space-y-10 tw-border-r tw-border-gray-200 md:tw-block md:tw-max-w-xs lg:tw-max-w-md"
        >
          <div class="tw-space-y-3 tw-bg-[url('/assets/background.svg')] tw-bg-contain tw-p-6">
            <Heading :level="2" mode="dark">Hi, {{ $auth?.user?.firstName }}</Heading>
            <Subheading mode="dark">View your claim status and required steps.</Subheading>
            <DashboardHeroTracker />
          </div>
          <SiteNavigation />
        </aside>
        <main class="tw-relative tw-flex tw-flex-1 tw-flex-col tw-bg-gray-100">
          <slot />
          <SiteAuthenticatedFooter />
        </main>
        <div
          class="tw-fixed tw-bottom-0 tw-h-[72px] tw-w-full tw-border-t-2 tw-border-gray-200 tw-bg-white md:tw-hidden"
        >
          <SiteNavigation />
        </div>
      </div>
    </section>
    <AppToast />
  </div>
</template>

<script setup lang="ts">
const open = useTrackerDrawer()
const { $auth } = useNuxtApp()

const query1 = useClaimStatusQuery()
const query2 = useActionRequestsQuery()

const isLoading = computed(() => !query1.isFetched.value && !query2.isFetched.value)

// Use the composable for dynamic head
const { applyHead } = useDynamicHead()
applyHead()

onMounted(() => {
  localStorage.removeItem('flow')
})
</script>
