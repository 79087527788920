<template>
  <nav
    class="tw-grid tw-grid-cols-3 tw-justify-between tw-text-gray-400 md:tw-block md:tw-space-y-6 md:tw-p-6 md:tw-py-2"
  >
    <NuxtLink
      :to="{ name: 'index' }"
      aria-label="Home"
      class="tw-flex tw-flex-col tw-items-center tw-gap-2 tw-rounded-md tw-p-2 tw-text-xs tw-transition-colors md:tw-flex-row md:tw-gap-y-0 md:tw-text-lg md:hover:tw-bg-slate-50"
      active-class="tw-text-gray-800 tw-font-medium"
      @click.prevent="$analytics.trackElementClicked({ elementType: 'navigation', text: 'home' })"
    >
      <Home class="!tw-text-2xl" />
      Home
    </NuxtLink>

    <NuxtLink
      :to="{ name: 'completed-actions' }"
      aria-label="Completed actions"
      class="tw-flex tw-flex-col tw-items-center tw-gap-2 tw-rounded-md tw-p-2 tw-text-xs tw-transition-colors md:tw-flex-row md:tw-gap-y-0 md:tw-text-lg md:hover:tw-bg-slate-50"
      active-class="tw-text-gray-800"
      @click.prevent="
        $analytics.trackElementClicked({ elementType: 'navigation', text: 'completed-actions' })
      "
    >
      <ClipboardDocumentList class="!tw-text-2xl" />
      Completed
    </NuxtLink>

    <NuxtLink
      :to="{ name: 'help' }"
      aria-label="Help"
      class="tw-flex tw-flex-col tw-items-center tw-gap-2 tw-rounded-md tw-p-2 tw-text-xs tw-transition-colors md:tw-flex-row md:tw-gap-y-0 md:tw-text-lg md:hover:tw-bg-slate-50"
      active-class="tw-text-gray-800"
      @click.prevent="$analytics.trackElementClicked({ elementType: 'navigation', text: 'help' })"
    >
      <QuestionMark class="!tw-text-2xl" />
      Help
    </NuxtLink>
  </nav>
</template>

<script setup lang="ts">
import Home from '~icons/heroicons-solid/home'
import QuestionMark from '~icons/heroicons-solid/question-mark-circle'
import ClipboardDocumentList from '~icons/heroicons/clipboard-document-list-solid'

const $analytics = useAnalytics()
</script>
